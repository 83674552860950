import React from 'react'

const NoMatch = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>このページは見つかりませんでした。</p>
    </div>
  )
}

export default NoMatch